<template>
  <div>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      sss
    </ValidationObserver>
    <pre v-if="debugMode">{{ actualForm }}</pre>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { isEmpty } from 'lodash'
import Config from '@/views/administration/plans/formConfig'

export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      actualForm: {
        record_status: false,
      },
      formMode: 'create',
    }
  },
  computed: {
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
    formTitle() {
      const title = this.formConfig.title[this.formMode]
      return title
    },
    formConfig() {
      if (Config) {
        return Config.formData()
      }
      return {}
    },
  },
  mounted() {
    if (!isEmpty(this.$route.params)) {
      this.formMode = this.$route.params.mode
    }

    if (this.formMode === 'edit'
        || this.formMode === 'remove'
        || this.formMode === 'view') {
      console.log(`${this.formMode} Mode`)
      this.$store.dispatch('banks/getBank', this.$route.params.id).then(({ data }) => {
        console.log(this.formMode, 'hit')
        this.actualForm = data.data
      })
    } else if (this.formMode === 'create') {
      console.log(`${this.formMode} Mode`)
    } else {
      console.log('Unknown Mode')
      this.$router.push({ name: this.parentPageNamedRoute })
    }
  },
  methods: {
    cancelForm() {
      this.$router.push({ name: this.parentPageNamedRoute })
    },
    resetForm() {
      requestAnimationFrame(() => {
        console.log(this.$refs.observer)
        this.$refs.observer.reset()
      })
    },
  },
}
</script>
